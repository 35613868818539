/* eslint-disable */
import Vue from 'vue';
import store from '@/store';

let isFirstNav = true;

export default async (to, from, next) => {
  if (isFirstNav) {
    try {
      await store.dispatch('auth/authenticate');
    } catch (e) {
      console.log('error on auth/authenticate ');
    }
    isFirstNav = false;
  }

  const requiresAuth = to.meta.auth !== false;
  const userAuthorized = store.state.auth?.accessToken;
  const userVerified = store.state.auth?.user?.isVerified;
  const redirectPath = from.fullPath ? from.fullPath : '/';

  // console.table({
  //   requiresAuth,
  //   userAuthorized,
  //   redirectPath
  // });

  // Vue.nextTick(() => {
  //   if (to.name) {
  //     document.title = to.name;
  //   }
  // });

  if (requiresAuth) {
    if (!userAuthorized) {
      next({
        name: 'login',
        params: { nextUrl: to.fullPath }
      });
    }

    if (to.name === 'verify' && userVerified) {
      next({ path: redirectPath });
      window.$notify.info('Account already verified. Page access denied.')
    } else {
      next();
    }
  } else if (to.name === 'login' && userAuthorized) {
    next({ path: redirectPath });
  } else {
    next();
  }

};
