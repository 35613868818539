import feathersClient, {
    makeServicePlugin,
    BaseModel
} from '@/plugins/feathersClient';

class Timeslots extends BaseModel {
    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = 'Timeslots';

    // Define default properties here
    static instanceDefaults() {
        return {};
    }
}
const servicePath = 'timeslots';
const servicePlugin = makeServicePlugin({
    Model: Timeslots,
    service: feathersClient.service(servicePath),
    servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
});

export default servicePlugin;
