<route>
{
"name": "404",
"meta": {
"auth": false
}
}
</route>

<template>
  <div id="app">
    <div class="flex">
      <div class="icon_not-found"></div>
      <div class="not_found_text">
        <div class="t1 mb-24">{{$t('404.looks_like_this_page_doesn')}}</div>
        <div class="t2 mb-32">{{$t('404.but_don_t_let_this_stop_you')}} <br>{{$t('404.check_any_of_these_pages')}}</div>
        <div>
          <UiLink to="/">{{$t('404.main_page')}}</UiLink>
        </div>
        <div>
          <UiLink to="/">{{$t('404.contact_us')}}</UiLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiLink from "@/components/ui/UiLink";

export default {
  name: "404",
  layout: "empty",
  components: {UiLink},
}
</script>

<style scoped lang="scss">
#app {
  align-items: center;
  justify-content: center;
}

.icon_not-found {
  width: 389px;
  height: 395px;
  background-size: contain;
  background-image: url("~@/assets/images/404.png");
}

.not_found_text {
  width: 538px;
  padding: 64px 52px;

  .t1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #272848;
  }

  .t2 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7D7D7D;
  }

}
</style>