export default {
    install(app) {

        app.config.globalProperties.$saveRouteParams = function () {
            // TODO: add sort params by key
            const usp = new URLSearchParams(this.$route.params);
            history.pushState(
                {},
                null,
                this.$route.path + '?' + usp.toString()
            )
        }

    }
}