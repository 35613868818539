import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '@/plugins/feathersClient';

class StudentStatus extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'StudentStatus';

  // Define default properties here
  static instanceDefaults() {
    return {
      createdAt: null,
      id: null,
      rejectReason: null,
      status: null,
      studentId: null,
      updatedAt: null
    };
  }
}
const servicePath = 'student-statuses';
const servicePlugin = makeServicePlugin({
  Model: StudentStatus,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
