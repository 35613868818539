/* eslint-disable */

export default {
  install(app, options) {
    app.config.globalProperties.$notify = {
      success(message) {
          app.config.globalProperties.$notification.success({
          message: 'Success!',
          description: message,
          class: 'custom-notifier',
          duration: 5
        });
      },
      info(description, message = 'Information!') {
          app.config.globalProperties.$notification.info({
          message,
          description,
          class: 'custom-notifier',
          duration: 5
        });
      },
      error(message, overrides = {}) {
          app.config.globalProperties.$notification.error({
          message: 'Error!',
          description: message,
          class: 'custom-notifier',
          duration: 5,
          ...overrides
        });
      },
      parseFeathersErrors(error, overrides) {
        if (error?.errors?.length) {
          return error.errors.map((e) => {
            this.error(e.message, overrides);
            return e.message;
          });
        } else if (error?.message) {
          this.error(error.message, overrides);
          return [ error.message ];
        } else {
          console.warn('parseFeathersErrors:', error);
          return [];
        }
      }
    };

    // for global using
    window.$notify = app.config.globalProperties.$notify;
  }
}
