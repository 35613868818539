import {toRaw} from 'vue'

export default {
    install(app) {

        app.config.globalProperties.$log = function (...args) {
            console.log(...args.map((arg) => toRaw(arg)));
        }

        app.config.globalProperties.$warn = function (...args) {
            console.warn(...args.map((arg) => toRaw(arg)));
        }

        app.config.globalProperties.$err = function (...args) {
            console.err(...args.map((arg) => toRaw(arg)));
        }

    }
}
