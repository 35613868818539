import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '@/plugins/feathersClient'

class Certificates extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Certificates'

  // Define default properties here
  static instanceDefaults() {
    return {
      email: '',
      level: '',
      firstName: '',
      lastName: '',
      dateFrom: '',
      dateTo: '',
      released: ''
    }
  }
}
const servicePath = 'certificates'
const servicePlugin = makeServicePlugin({
  Model: Certificates,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
