import { createStore } from 'vuex'
import { FeathersVuex, makeAuthPlugin } from '@/plugins/feathersClient'
import { caslPlugin } from './modules/casl'

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

export default createStore({
  state: {
    dsList: {},
  },
  mutations: {},
  actions: {},
  // modules,
  plugins: [...servicePlugins, makeAuthPlugin({ userService: 'users' }), caslPlugin],
  FeathersVuex: FeathersVuex,
})
