import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '@/plugins/feathersClient';

class CurriculumVitae extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'CurriculumVitae';

  // Define default properties here
  static instanceDefaults() {
    return {
      id: '',
      key: '',
      name: '',
      purpose: '',
      provider: '',
      bucket: '',
      mime: '',
      thumbnailOf: '',
      createdBy: ''
    };
  }
}
const servicePath = 'curriculum-vitaes';
const servicePlugin = makeServicePlugin({
  Model: CurriculumVitae,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
