import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../plugins/feathersClient'

class Country extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Country'

  // Define default properties here
  static instanceDefaults() {
    return {
      name: '',
      alpha2Code: '',
      altSpellings: [],
      callingCodes: [],
      timezones: [],
      languages: [],
      flagUrl: ''
    }
  }
}
const servicePath = 'countries'
const servicePlugin = makeServicePlugin({
  Model: Country,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
