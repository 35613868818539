import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '@/plugins/feathersClient';

class StudentDetail extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'StudentDetail';

  // Define default properties here
  static instanceDefaults() {
    return {
      hasAllowedProfession: null,
      currentProfession: '',
      prc: '',
      countExperience: '',
      countAbroadExperience: '',
      departments: []
    };
  }
}
const servicePath = 'students-details';
const servicePlugin = makeServicePlugin({
  Model: StudentDetail,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
