import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';

import AuthManagement from 'feathers-authentication-management/src/client';

import io from 'socket.io-client';
import { iff, discard } from 'feathers-hooks-common';
import feathersVuex from '@feathersjs/vuex';
import config from '../config';

console.log('Backend url:', config.backendUrl);
const socket = io(config.backendUrl, {
  transports: ['websocket'],
  forceNew: true
});

const feathersClient = feathers()
  .configure(socketio(socket))
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [iff((context) => ['create', 'update', 'patch'].includes(context.method), discard('__id', '__isTemp'))]
    },
    //  daffl commented on 28 Apr 2020
    // Remember that when using Feathers on the client you get the same functionality of the core application,
    // including application level hooks. Any error can be handled with an application level error hook:
    error: {}
  });

export default feathersClient;

const authManagementClient = new AuthManagement(feathersClient);
// Setting up feathers-vuex
const {
 makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex 
} = feathersVuex(feathersClient, {
  serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
  idField: 'id', // Must match the id field in your database table/collection
  whitelist: ['$regex', '$options']
});

export {
 makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex, authManagementClient 
};
