import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueRouterLayout from 'vue-router-layout'

import 'ant-design-vue/dist/antd.css'
import '@/assets/styles/main.scss'

import Antd from 'ant-design-vue'
import { Can, abilitiesPlugin } from '@casl/vue'

import notify from '@/plugins/notify'
import dayjs from '@/plugins/dayjs'
import logger from '@/plugins/logger'
import routeUtils from '@/plugins/routeUtils'
import i18n from '@/plugins/i18n'
import { FeathersVuex } from './plugins/feathersClient'

createApp(App)
  .use(Antd)
  .use(VueRouterLayout)
  .use(store)
  .use(FeathersVuex)
  .use(abilitiesPlugin, store.state.casl.ability, { useGlobalProperties: true })
  .component(Can.name, Can)
  .use(router)
  .use(notify)
  .use(dayjs)
  .use(logger)
  .use(routeUtils)
  .use(i18n)
  .mount('#app')
